import IconSelector from "../../js/components/IconSelector";
import SettingsCard from "../../js/components/SettingsCard/SettingsCard";
import { useTranslation } from "react-i18next";

export const Settings = () => {
  const { t } = useTranslation();

  return (
    <>
      <div className="c-settings">
        <div className="settings-title">{t("settings.settings")}</div>
        <div className="settings-subtitle">{t("settings.appSettings")}</div>
        <div className="c-options">
          <div className="c-options-col">
            <SettingsCard
              icon={<IconSelector svg={"SettingsUser"} />}
              title={t("settings.personalData")}
              subtitle={t("settings.updatePersonalData")}
              to={"/personal-information"}
            ></SettingsCard>
            <SettingsCard
              icon={<IconSelector svg={"SettingsLocations"} />}
              title={t("settings.locationsData")}
              subtitle={t("settings.updateLocationsData")}
              to={"/locations"}
            ></SettingsCard>
          </div>
          <div className="c-options-col">
            <SettingsCard
              icon={<IconSelector svg={"SettingsOrg"} />}
              title={t("settings.orgData")}
              subtitle={t("settings.updateOrgData")}
              to={"/org-information"}
            ></SettingsCard>
            <SettingsCard
              icon={<IconSelector svg={"SettingsTools"} />}
              title={t("settings.toolsData")}
              subtitle={t("settings.updateToolsData")}
              to={"/tools"}
            ></SettingsCard>
          </div>
          <div className="c-options-col">
            <SettingsCard
              icon={<IconSelector svg={"SettingsTeam"} />}
              title={t("settings.teamData")}
              subtitle={t("settings.updateTeamData")}
              to={"/team-members"}
            ></SettingsCard>
          </div>
        </div>
      </div>
    </>
  );
};
