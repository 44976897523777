import React, { useState } from "react";
import { Select, MenuItem } from "@mui/material";
import { useTranslation } from "react-i18next";

const DurationSelector = ({ params }) => {
  const { t } = useTranslation();
  const { id, formType, validations, formProps, isEditing } = params;

  return (
    <div className="form-content-component">
      <label className="colored-label">
        {t(`${formType}.fields.${id}`)}
        {validations.filter((rule) => rule.type === "required").length > 0 &&
          "*"}
      </label>
      <Select
        value={formProps.values[id] || 1}
        onChange={(event) => {
          formProps.setFieldValue(id, event.target.value);
        }}
        className="duration-input"
        MenuProps={{
          PaperProps: {
            style: {
              maxHeight: "200px",
              width: "15px",
              overflowY: "auto",
              marginTop: "8px",
            },
          },
        }}
      >
        {Array.from({ length: 24 }, (_, i) => (
          <MenuItem className="duration-menuItem" key={i + 1} value={i + 1}>
            {i + 1}h
          </MenuItem>
        ))}
      </Select>
    </div>
  );
};

export default DurationSelector;
